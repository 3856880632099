body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import '~antd/dist/antd.less'; */
/* OVERRIDE GLOBAL -------- */
.ant-input-lg {
  font-size: 16px;
}
/*--------------------------*/
.App-logo {
  flex: 2 1;
  text-align: center;
}
.App-logo img{
  /* height: 80px; */
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid lightgrey;
}
.App-header > .links {
  flex: 1 1;
}
.App-header > .links > a {
  text-decoration: underline;
  margin-right: 16px;
}
/* .App-header > .links > a:not(:last-child) {
  margin-left: 0px;
} */

.App-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  background: #fe5500;
  color: white;
}
.App-footer h3 {
  color: white;
  text-decoration: underline;
}
.App-footer p {
  margin: 0;
}
.App-footer a {
  margin-right: 16px;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 32px;
}

.banner {
  background-image: url(/static/media/banner.982005a0.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  background-position-x: center;
  background-position-y: center;
}

.allStepsBanner {
  background: lightgrey;
  padding: 32px 0;
}
@media only screen and (max-width: 600px) {
  h2.step-title {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .step-desc {
    display: flex;
    width: 100%;
  }
  .step-desc > svg {
    width: 40px;
    margin-left: 8px;
  }
}
h2.step-title {
  margin-bottom: 32px;
}

.desc {
  margin: 0;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
.description-elem {
  height: 45px;
}

.ant-card {
  margin-bottom: 32px;
}

.infoBlock {
  padding: 16px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
  display: inline-block;
  border-radius: 3px;
  width: 100%;
  height: 150px;
}

.controls {
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .controls {
    justify-content: center;
  }
}
.button-apadrinhe {
  /* font-size: 28px;
  height: auto;
  padding: 8px 24px; */
  margin: 16px;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding: 80px 16px;
}

.steps-action {
  margin-top: 24px;
  text-align: center;
}
.form-container {
  max-width: 550px;
  margin: 0 auto;
  padding: 0 24px;
}
.input-container {
  margin: 32px 0;
  text-align: left;
  position: relative;
}
.input-container > label {
  font-weight: bold
}
.input-container > .error-field {
  position: absolute;
  bottom: -20px;
  margin: 0;
  color: red;
}
.input-selection {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 16px;
  margin: 24px;
  cursor: pointer;
}
.primary-label {
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}
.secondary-label {
  font-size: 12px;
  margin: 0;
}

.confirmation-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 24px;
  border: 1px solid #ff4300;
  border-radius: 5px;
  font-size: 16px;
}
.confirmation-label {
  margin: 8px 0;
  text-align: right;
}
.confirmation-value {
  margin: 8px 0;
  font-weight: bold;
  text-align: left;
}
